import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Presentation from './pages/Presentation/Presentation';
import MobileRedirect from './pages/MobileRedirect/MobileRedirect';

const App = (): JSX.Element => {
    return (
        <Router>
            <Switch>
                <Route path="/presentations/:id" component={Presentation} />
                <Route path="/mobile" component={MobileRedirect} />
            </Switch>
        </Router>
    );
};

export default App;
