import Konva from 'konva';
import React, { useEffect, useRef } from 'react';
import { Stage } from 'react-konva';
import style from './RectangleKonvaBase.module.scss';
import { Transform } from './types';

export function RectangleKonvaBase({
    initialTransform,
    children,
}: {
    initialTransform?: Transform;
    children?: JSX.Element | JSX.Element[] | null;
}): JSX.Element {
    const container = useRef<HTMLDivElement>(null);
    const stage = useRef<Konva.Stage>(null);

    useEffect(() => {
        function fix() {
            if (container.current) {
                stage.current?.size({
                    width: container.current.parentElement?.offsetWidth ?? 1,
                    height: container.current.parentElement?.offsetHeight ?? 1,
                });
            }
        }

        fix();

        window.addEventListener('resize', fix);
        return () => window.removeEventListener('resize', fix);
    }, []);

    useEffect(() => {
        const oldPosition = initialTransform?.transform ?? { x: 0, y: 0 };
        const oldScale = initialTransform?.scale ?? 1.0;
        const oldRotation = initialTransform?.rotate ?? 0.0;
        const layer = stage.current?.children && stage.current.children[0];
        stage?.current?.position(oldPosition);

        stage?.current?.position({
            x: oldPosition.x === 0 ? 0 : stage.current.width() / oldPosition.x,
            y: oldPosition.y === 0 ? 0 : stage.current.height() / oldPosition.y,
        });
        stage?.current?.scale({ x: oldScale, y: oldScale });

        layer?.rotation(oldRotation);
    }, [stage]);

    return (
        <div ref={container} className={style.Canvas}>
            <Stage width={100} height={100} ref={stage}>
                {children}
            </Stage>
        </div>
    );
}
