import React, { useEffect, useState } from 'react';
import './PresentationEditRectItem.scss';
import { Text } from '../types';
import DOMPurify from 'dompurify';

type PresentationEditTextItemProps = {
    id: string;
    text: Text;
};

const PresentationEditTextItem = ({
    id,
    text,
}: PresentationEditTextItemProps): JSX.Element => {
    const [inputText, setInputText] = useState('');
    const percentOriginalHeight = text.box.height / 1080;
    const percentOriginalWidth = text.box.width / 1920;
    const percentHeight =
        document.getElementById('presentation_page_0')?.clientHeight || 0;
    const percentWidth =
        document.getElementById('presentation_page_0')?.clientWidth || 0;

    useEffect(() => {
        setInputText(text.editing.value ?? text.text);
    }, [text]);

    const parentBoxX = text.box.x / 1920;
    const parentBoxY = text.box.y / 1080;

    const size = text.size / 1080;

    const { r = 0, g = 0, b = 0, a = 1 } = text.style.color ?? {};

    const formattedText = (inputText || text.text)
        .split('\n')
        .map((str, index) => (
            <React.Fragment key={index}>
                {DOMPurify.sanitize(str)}
                {index < (inputText || text.text).split('\n').length - 1 && (
                    <br />
                )}
            </React.Fragment>
        ));

    return (
        <p
            id={id}
            key={id}
            style={{
                position: 'absolute',
                marginLeft: percentWidth * parentBoxX,
                marginTop: percentHeight * parentBoxY,
                textOverflow: 'ellipsis',
                width: percentWidth * percentOriginalWidth,
                height: percentOriginalHeight * percentHeight,
                color: `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a})`,
                textAlign: text.align ?? 'center',
                fontSize: percentHeight * size * 0.9,
                fontFamily: `${DOMPurify.sanitize(
                    text.style.fontFamily ?? 'Roboto'
                )}, sans-serif`,
                fontWeight: text.weight,
            }}
        >
            {formattedText}
        </p>
    );
};

export default PresentationEditTextItem;
