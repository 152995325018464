import React, { useState, useCallback } from 'react';

import { PresentationEditRect } from './items/PresentationEditRect';
import { RectangleKonvaBase } from './RectangleKonvaBase';
import Konva from 'konva';
import { Rect } from './types';
type RectOption = 'ROTATE' | 'ZOOM' | 'TRANSFORM';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RectComponentOptions = React.createContext<{ [key: string]: any }>(
    {}
);

export function RectComponent({ rect }: { rect: Rect }): JSX.Element {
    const [option, setOption] = useState<RectOption>('TRANSFORM');
    const imageRef = React.useRef<Konva.Image>(null);

    const getOption = useCallback(() => {
        return option;
    }, [option]);

    const percentOriginalHeight = rect.box.height / 1080;
    const percentOriginalWidth = rect.box.width / 1920;

    const percentHeight =
        document.getElementById('presentation_page_0')?.clientHeight || 0;
    const percentWidth =
        document.getElementById('presentation_page_0')?.clientWidth || 0;

    const parentBoxX = rect.box.x / 1920;
    const parentBoxY = rect.box.y / 1080;

    const radius = rect.cornerRadius;

    const initialTransform = () => {
        return {
            rotate: rect.editing?.rotate ?? 0,
            scale: rect.editing?.scale ?? 1,
            transform: {
                x: rect.editing?.transform.x ?? 0,
                y: rect.editing?.transform.y ?? 0,
            },
            reflection: {
                x: rect.editing?.reflection?.x ?? 1,
                y: rect.editing?.reflection?.y ?? 1,
            },
        };
    };

    return rect.locked ? (
        <div
            id={rect._id}
            style={{
                overflow: 'hidden',
                position: 'absolute',
                marginLeft: percentWidth * parentBoxX,
                marginTop: percentHeight * parentBoxY,
                width: percentWidth * percentOriginalWidth,
                height: percentOriginalHeight * percentHeight,
                borderRadius: radius ?? 0,
            }}
        />
    ) : (
        <RectComponentOptions.Provider
            value={{
                getOption,
                setOption,
            }}
        >
            <div id={rect._id}>
                <div
                    className={
                        rect.editable
                            ? 'rect_component'
                            : 'rect_component_static'
                    }
                    style={{
                        marginLeft: percentWidth * parentBoxX,
                        marginTop: percentHeight * parentBoxY,
                        width: percentWidth * percentOriginalWidth - 4,
                        height: percentOriginalHeight * percentHeight - 4,
                        borderRadius: radius ?? 0,
                    }}
                >
                    <div
                        className="image_presentation_container"
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                        }}
                        id={rect._id}
                        key={rect._id}
                    >
                        <RectangleKonvaBase
                            initialTransform={initialTransform()}
                        >
                            <PresentationEditRect
                                size={{
                                    width:
                                        percentWidth * percentOriginalWidth - 4,
                                    height:
                                        percentOriginalHeight * percentHeight -
                                        4,
                                }}
                                imageSrc={rect.url}
                                reflection={initialTransform().reflection}
                            />
                        </RectangleKonvaBase>
                    </div>
                </div>
            </div>
        </RectComponentOptions.Provider>
    );
}
