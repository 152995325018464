import React from 'react';
import { Layer, Line, Rect, Stage, Arrow } from 'react-konva';
import {
    IArrow,
    ILine,
    IRectangle,
} from '../../../interfaces/PresentationShapes.interface';

type PresentationStageProps = {
    shapes: {
        curves: ILine[];
        rectangles: IRectangle[];
        arrows: IArrow[];
        lines: ILine[];
    };
    width: number;
    height: number;
};

const scalePoints = (
    points: number[],
    width: number,
    height: number
): number[] => {
    return points.map((point, index) =>
        index % 2 === 0 ? point * width : point * height
    );
};

const PresentationStage = ({
    shapes,
    width,
    height,
}: PresentationStageProps): JSX.Element => {
    return (
        <Stage width={width} height={height}>
            <Layer>
                {shapes.curves.map((curve) => (
                    <Line
                        key={curve.id}
                        points={scalePoints(curve.points, width, height)}
                        stroke={curve.color}
                        strokeWidth={curve.width}
                    />
                ))}
                {shapes.rectangles.map((rectangle) => (
                    <Rect
                        key={rectangle.id}
                        x={rectangle.x * width}
                        y={rectangle.y * height}
                        width={rectangle.width * width}
                        height={rectangle.height * height}
                        fill={'transparent'}
                        stroke={rectangle.stroke}
                        offsetX={(rectangle.width * width) / 2}
                        offsetY={(rectangle.height * height) / 2}
                        rotation={rectangle.rotation}
                    />
                ))}
                {shapes.lines.map((line) => (
                    <Line
                        key={line.id}
                        points={scalePoints(line.points, width, height)}
                        stroke={line.color}
                        strokeWidth={line.width}
                    />
                ))}
                {shapes.arrows.map((arrow) => (
                    <Arrow
                        key={arrow.id}
                        points={[
                            arrow.startPoint.x * width,
                            arrow.startPoint.y * height,
                            arrow.endPoint.x * width,
                            arrow.endPoint.y * height,
                        ]}
                        stroke={arrow.color}
                        fill={arrow.color}
                        strokeWidth={arrow.width}
                    />
                ))}
            </Layer>
        </Stage>
    );
};

export default PresentationStage;
