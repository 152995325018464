// extracted by mini-css-extract-plugin
module.exports =  {"mobileRedirect":"nKpskxYv8KEqtH1W6Jn6HQ==","mobileRedirect__messageText":"jJHE32JnYpUdqG-kvKSu1A=="};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"mobileRedirect\":\"nKpskxYv8KEqtH1W6Jn6HQ==\",\"mobileRedirect__messageText\":\"jJHE32JnYpUdqG-kvKSu1A==\"}";
        // 1725469027540
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"esModule":false});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  