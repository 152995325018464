import React, { useEffect, useRef, useState } from 'react';
import './PresentationEditPage.scss';
import { RectComponent } from './RectComponent';
import PresentationEditTextItem from './items/PresentationEditTextItem';
import { Page } from './types';
import PresentationStage from './PresentationStage/PresentationStage';

type PresentationEditPageProps = {
    page: Page | null;
    isFirst?: boolean;
    index?: number;
};

const PresentationEditPage = (
    props: PresentationEditPageProps
): JSX.Element => {
    const { page, index, isFirst } = props;
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const pageRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (pageRef.current) {
            const { clientWidth, clientHeight } = pageRef.current;
            setStageSize({ width: clientWidth, height: clientHeight });
        }
    }, [page]);

    return (
        <div className="presentation_page_root">
            <div
                className="presentation_page"
                ref={pageRef}
                id={
                    isFirst
                        ? 'presentation_page_0'
                        : `presentation_page_${(index || 0) + 1}`
                }
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        minBlockSize: '100%',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <div>
                        <div
                            style={{
                                width: '-webkit-fill-available',
                                height: '-webkit-fill-available',
                                position: 'absolute',
                            }}
                        />
                    </div>

                    {(page?.images ?? []).map((rect, index) => (
                        <RectComponent rect={rect} key={index} />
                    ))}
                    {(page?.texts ?? []).map((text, index) => (
                        <PresentationEditTextItem
                            id={text.id}
                            text={text}
                            key={index}
                        />
                    ))}
                    <div className={'stageContainer'}>
                        <PresentationStage
                            shapes={
                                page?.shapes ?? {
                                    curves: [],
                                    rectangles: [],
                                    arrows: [],
                                    lines: [],
                                }
                            }
                            width={stageSize.width}
                            height={stageSize.height}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PresentationEditPage;
