import React, { useEffect, useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { presentationAPIs } from '../../api/Presentation.api';

import '../../components/Page/Presentation.scss';
import { UiButton } from '../../components/ui';
import '../../App.scss';
import PresentationEditPage from '../../components/Page/PresentationEditPage';
import { Page } from '../../components/Page/types';
import { useParams } from 'react-router';

const Presentation = (): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const [pages, setPages] = useState<Page[]>([]);
    const pagesRef = useRef<HTMLDivElement[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState('presentation.pdf');

    useEffect(() => {
        presentationAPIs.getPresentation(id ?? '').then((res) => {
            setPages(res.data.pages);
            setFileName(`${res?.data?.pdf_name || 'presentation'}.pdf`);
        });
    }, [id]);

    const handleSavePdf = async () => {
        setIsLoading(true);
        const pdf = new jsPDF('landscape', 'px', 'a4');
        for (let i = 0; i < pagesRef.current.length; i++) {
            const page = pagesRef.current[i];
            const canvas = await html2canvas(page, { scale: 2 });
            const imgData = canvas.toDataURL('image/jpeg');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            if (i > 0) {
                pdf.addPage();
            }
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        }
        pdf.save(fileName);
        setIsLoading(false);
    };

    return (
        <div className="root">
            <div className="header">
                <div className="header__container">
                    <div className="logo" />
                    <UiButton
                        style={{ minWidth: 173 }}
                        onClick={handleSavePdf}
                        isLoading={isLoading}
                    >
                        Сохранить в PDF
                    </UiButton>
                </div>
            </div>
            <div className="user_info_header_separator" />
            <div className="container">
                <div ref={(el) => (pagesRef.current[0] = el!)}>
                    <PresentationEditPage page={pages[0]} isFirst index={0} />
                </div>
                {pages.slice(1).map((page, index) => (
                    <div
                        key={index}
                        ref={(el) => (pagesRef.current[index + 1] = el!)}
                    >
                        <PresentationEditPage page={page} index={index} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Presentation;
