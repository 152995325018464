import React, { ButtonHTMLAttributes, FC } from 'react';

import loadingIcon from '../../../images/loading.svg';

import style from './UiButton.module.scss';

interface IUiButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
}

export const UiButton: FC<IUiButton> = ({ isLoading, ...props }) => {
    return (
        <button {...props} type={props.type || 'button'} className={style.root}>
            {isLoading ? (
                <img className={style.img} src={loadingIcon} alt="loading" />
            ) : (
                <span>{props.children}</span>
            )}
        </button>
    );
};
