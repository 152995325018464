import React, { useEffect, useState, useMemo } from 'react';
import { Layer, Image as KImage } from 'react-konva';
import Konva from 'konva';

type Size = {
    width: number;
    height: number;
};

type Reflection = {
    x: number;
    y: number;
};

export function PresentationEditRect({
    imageSrc,
    size,
    reflection,
}: {
    imageSrc: string | undefined;
    size: Size;
    reflection: Reflection;
}): JSX.Element {
    const [img, setImage] = useState<HTMLImageElement>();
    const imageRef = React.useRef<Konva.Image>(null);

    useEffect(() => {
        const image = new Image();
        const onLoad = () => setImage(image);
        image.addEventListener('load', onLoad);
        image.crossOrigin = '*';
        image.src = imageSrc ?? '';

        return () => {
            image.removeEventListener('load', onLoad);
        };
    }, [imageSrc]);

    const scaledSize = useMemo(() => {
        if (!img) {
            return { width: 0, height: 0 };
        }

        const scale = Math.max(
            size.width / img.width,
            size.height / img.height
        );
        return {
            width: img.width * scale,
            height: img.height * scale,
        };
    }, [img, size]);

    const position = useMemo(
        () => ({
            x: size.width / 2 - scaledSize.width / 2,
            y: size.height / 2 - scaledSize.height / 2,
        }),
        [size, scaledSize]
    );

    return (
        <Layer>
            <KImage
                ref={imageRef}
                image={img}
                width={scaledSize.width}
                height={scaledSize.height}
                x={position.x + (reflection.x === -1 ? scaledSize.width : 0)}
                y={position.y + (reflection.y === -1 ? scaledSize.height : 0)}
                scaleX={reflection.x === -1 ? -1 : 1}
                scaleY={reflection.y === -1 ? -1 : 1}
            />
        </Layer>
    );
}
