import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import style from './MobileRedirect.module.scss';

const MobileRedirect: React.FC = () => {
    useEffect(() => {
        const checkApp = () => {
            const appScheme = 'dilab://';
            const appStoreURL =
                'https://apps.apple.com/ru/app/dilab/id1626143338';
            const playStoreURL =
                'https://play.google.com/store/apps/details?id=com.vibelab.dilab&hl=ru&pli=1';

            let isAppInstalled = false;

            const timeout = setTimeout(() => {
                if (!isAppInstalled) {
                    window.location.href = isAndroid()
                        ? playStoreURL
                        : appStoreURL;
                }
            }, 10000);

            const detectApp = () => {
                window.location.href = appScheme;

                const onVisibilityChange = () => {
                    if (document.visibilityState === 'visible') {
                        clearTimeout(timeout);
                        isAppInstalled = true;
                        document.removeEventListener(
                            'visibilitychange',
                            onVisibilityChange
                        );
                    }
                };

                document.addEventListener(
                    'visibilitychange',
                    onVisibilityChange
                );
            };

            const isAndroid = () => /Android/i.test(navigator.userAgent);

            detectApp();
        };

        checkApp();
    }, []);

    return (
        <>
            <Helmet>
                <meta
                    name="apple-itunes-app"
                    content="app-id=1626143338, app-argument=dilab://"
                />
            </Helmet>
            <div className={style.mobileRedirect}>
                <span className={style.mobileRedirect__messageText}>
                    Если приложение установлено, оно откроется автоматически.
                    Если нет, вы будете перенаправлены в магазин приложений.
                </span>
            </div>
        </>
    );
};

export default MobileRedirect;
