// extracted by mini-css-extract-plugin
module.exports =  {"root":"Fp0bFYbCnLt4Z06TyeNYFw==","img":"fpqHMtPu56YTx96kOXqPOA==","rotate-animation":"c7inXKKT72eI0VgkES91qg=="};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"root\":\"Fp0bFYbCnLt4Z06TyeNYFw==\",\"img\":\"fpqHMtPu56YTx96kOXqPOA==\",\"rotate-animation\":\"c7inXKKT72eI0VgkES91qg==\"}";
        // 1725469028231
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {"esModule":false});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  