import { Page } from '../components/Page/types';
import { AxiosInstance } from './AxiosInstance';
import { AxiosResponse } from 'axios';

class PresentationApis {
    async getPresentation(id: string): Promise<
        AxiosResponse<{
            pages: Page[];
            pdf_name: string;
        }>
    > {
        return await AxiosInstance.get('/public/presentations/' + id);
    }
}

export const presentationAPIs = new PresentationApis();
